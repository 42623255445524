import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../Utils/Axios.js";
import { BASE_URL } from "../../Utils/Urls";
import { handleError } from "../../Services/ErrorHandlerServices";

export const classOverViewDetailsServices = createAsyncThunk(
  "classOverViewDetailsServices",
  async () => {
    try {
      let url = `${BASE_URL}/teacher/teacher-class-overview`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      //   console.log(error);
      //   console.log(error);
      handleError(error);
      throw error;
    }
  }
);


export const classRequiredSessionServices = createAsyncThunk(
  "classRequiredSessionServices",
  async () => {
    try {
      let value = new Date().getMonth()+1
      let year = new Date().getFullYear()

      let url = `${BASE_URL}/teacher/teacher-required-session-details/?month=${value}&year=${year}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      //   console.log(error);
      //   console.log(error);
      handleError(error);
      throw error;
    }
  }
);





export const readNotifactionServices = createAsyncThunk(
  "readNotifactionServices",
  async (payload) => {
    // console.log(payload);
    try {
      let url = `${BASE_URL}/notification/read-teacher-notification`;
      const res = await axios.post(url, {});
      return res.data;
    } catch (error) {
      // handleError(error);
      throw error;
    }
  }
);


export const readSingleNotifactionServices = createAsyncThunk(
  "readSingleNotifactionServices",
  async (payload) => {
    try {
      let url = `${BASE_URL}/notification/read-single-notification`;
      const res = await axios.post(url,payload);
      return res.data;
    } catch (error) {
      // handleError(error);
      throw error;
    }
  }
);